body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.notification_not_seen p{
  font-weight: bold;
}
.referral-card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.referral-card .card-header {
  background-color: #f8f9fa;
  border-bottom: none;
}
.referral-card .share-icons {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;
}
.referral-card .share-icons:hover {
  color: #0056b3;
}
.ck input{
  padding:10px;
  border-radius: 20px;
  border:1px solid #00000020;
  width:100%;
  cursor: pointer;
  margin:10px 0;
  font-size: 15px;
  font-weight: bold;
}
.sl{
  text-align: center;
  font-weight: bold;
}

.ctbtn{
  position: absolute;
    margin: 0;
    padding: 13px 0 0 0;
    right: 5%;
}
.top_service_name{
  text-transform: capitalize;
  color: #000;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

}
.heart-active{
  color: red !important;
}
.bg-opacity{
  height: 100%;
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  bottom: 0;
  opacity: 0.4;
  z-index: 9999;

}
.mdl{
  display: block;
    opacity: 1 !important;
    /* background: black; */
    z-index: 999999;
    margin-top: 10%;
    padding: 32px;
}

.star-rating {
  display: inline-block;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating label {
  float: right;
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 50px;
  line-height: 1;
}

.star-rating label:before {
  content: '\2605';
  color: #ccc;
}

.star-rating input[type="radio"]:checked ~ label:before {
  color: gold;
}
.order_request{
  font-family: "Judson", serif;
  font-weight: 700;
  font-style: normal;
}
.order_request h4:last-child{
  color:blue;
}




.order_input{
  margin-top:20px;
}
.send_booking{
  background-color: #4200FF;
  color: #fff;
  font-family: "Judson", serif;
  font-weight: 700;
  font-size: 20px;
  border: none;
  padding:5px 20px;
  border-radius: 5px;

}
.order_input .input-container {
  position: relative;
  margin-bottom: 20px;
}

.order_input .input-container label {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #999;
  font-size: 14px;
  pointer-events: none; /* Ensures label does not interfere with input focus */
  transition: all 0.2s ease;
}

.order_input .input-container input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

.order_input .input-container input:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.order_input .input-container input:focus + label,
.order_input .input-container input:not(:placeholder-shown) + label {
  font-size: 12px;
  top: -10px;
  background-color: white;
  padding: 2px 5px;
  color: #333;
}
.booking_info{
  padding: var(--bs-modal-padding);


}
.booking_info li{
    font-family: "Judson", serif;
  font-weight: 400;
  color: #979696;
  line-height: 23px;
}
.success{
  margin: 0 auto;
  text-align: center;
}
.success h1{
  font-family: "Judson", serif;
  font-weight: 700;
  color: green;
  line-height: 30px;
  font-size: 30px;
  margin-bottom: 10px;
}
.success p{
  font-family: "Judson", serif;
  font-weight: 400;
  font-size: 16px;
}
.success img{
  height: 250px;
  width: 250px;
}
.suc{
  margin: 0 auto;
}

.serviceInfoBx .servicItemRght .extra_filled_button{
  font-size: 17px;
}
.servicItemRght{
  margin-top: 3%;
}



.autocomplete {
  position: relative;
  display: inline-block; /* Ensures the input and list are aligned properly */
  width: 300px; /* Adjust as needed */
}

#autocompleteInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.autocomplete-results {
  position: absolute;
  z-index: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none; /* Remove top border to align with input */
  border-radius: 0 0 4px 4px; /* Rounded corners for bottom */

}

.autocomplete-results li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.autocomplete-results li:hover {
  background-color: #f1f1f1;
}









.about-section {
  padding: 100px 0;
  background-color: #f8f9fa;
  text-align: center;
}

.about-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.about-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.ceo-section {
  padding: 50px 0;
  background-color: #ffffff;
  text-align: center;
}

.ceo-section img {
  max-width: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.ceo-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.ceo-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.ceo-section p {
  font-size: 18px;
  color: #6c757d;
}

.team-section {
  padding: 50px 0;
  background-color: #f8f9fa;
}

.team-member {
  text-align: center;
  margin-bottom: 30px;
}

.team-member img {
  max-width: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-member h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.team-member p {
  color: #6c757d;
}




.terms .container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.8;
}

.terms header {
  text-align: center;
  margin-bottom: 30px;
  padding: 0;
}

.terms header h1 {
  color: #333;
  font-size: 2.5em;
}

.terms section {
  margin-bottom: 30px;
  padding: 0;
}

.terms h2 {
  color: #333;
  font-size: 1.8em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.terms p {
  color: #666;
  font-size: 1em;
  margin-bottom: 15px;
}

.terms strong {
  font-weight: bold;
}

.terms footer {
  text-align: center;
  margin-top: 30px;
  color: #999;
  font-size: 0.9em;
  padding: 0;
}

.terms footer a {
  color: #4caf50;
  text-decoration: none;
}

.terms footer a:hover {
  text-decoration: underline;
}








.privacy .container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.8;
}

.privacy header {
  text-align: center;
  margin-bottom: 30px;
  padding: 0;
}

.privacy header h1 {
  color: #333;
  font-size: 2.5em;
}

.privacy section {
  margin-bottom: 30px;
  padding: 0;
}

.privacy h2 {
  color: #333;
  font-size: 1.8em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.privacy p {
  color: #666;
  font-size: 1em;
  margin-bottom: 15px;
}

.privacy strong {
  font-weight: bold;
}

.privacy footer {
  text-align: center;
  margin-top: 30px;
  color: #999;
  font-size: 0.9em;
  padding: 0;
}

.privacy footer a {
  color: #4caf50;
  text-decoration: none;
}

.privacy footer a:hover {
  text-decoration: underline;
}






.contact .container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.8;
}

.contact header {
  text-align: center;
  margin-bottom: 30px;
}

.contact header h1 {
  color: #333;
  font-size: 2.5em;
}

.contact header p {
  color: #666;
  font-size: 1.2em;
  margin-top: 10px;
}

.contact form {
  margin-bottom: 30px;
}

.contact .form-group {
  margin-bottom: 20px;
}

.contact label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.contact input[type="text"],
.contact input[type="email"],
.contact textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact textarea {
  resize: vertical;
}

.contact button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
}

.contact button:hover {
  background-color: #45a049;
}

.contact footer {
  text-align: center;
  color: #666;
  font-size: 0.9em;
  margin-top: 20px;
}

.contact footer a {
  color: #4caf50;
  text-decoration: none;
}

.contact footer a:hover {
  text-decoration: underline;
}
#feedback-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#feedback-form textarea {
  resize: vertical;
}

#feedback-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
}

#feedback-form button:hover {
  background-color: #45a049;
}
#feedback-form{
  padding:20px 5%;
}
.col-sm-6{
  width: 50%;
  text-align: center;
}

.servicards{
  margin:5px;
}


.screen-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #c8c8c8;
  width: 117px;
  height: 117px;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  transition: 0.6s ease-in-out;
  background-image: linear-gradient(180deg, #D4A4FF 18.85%, #FFF 49.67%, #CD96FF 80.38%);
  margin: 0 auto;
  overflow: hidden;
}

.screen-box img {
  max-width: 75px;
  height: auto;
  object-fit: cover;
  margin-bottom: 5px;
}
.screen-box-outer span {
  color: #848484;
  font-family: Judson;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
  display: block;
  text-align: center;
}
.activity-bx{
  margin:10px 0;
}

.box-inner{
  margin:10px 0;
}
.servicebox h6{
  font-size:22px !important;
}
.servicebox{
  margin-bottom: 20px;
}
.locationtext i{
margin-right: 10px;
}








@media (min-width: 600px) {
  #navbarSupportedContent{
    justify-content: end;
  }
  .booking_area .nav-tabs .nav-link{
    background-color: transparent;
  }
  .text-end{
    left:60%;
  }
 .main-nav nav .navbar-nav .nav-item a{
    margin-left: 15px;
      margin-right: 15px;
  }
  .mobile_only{
    display: none !important;
  }
  .sticky{
    background: gray !important;
    padding: 10px 0;
  }
  .sticky a{
    color:#fff !important;
  }
  .mdl .modal-dialog{
    max-width: 80% !important;
  }
  .desk{
    display: block;
  }
  .mobl{
    display: none;
  }
}


@media (max-width: 600px) {
  .desk{
    display: none;
  }
  .mobl{
    display: block;
  }
  .mobl .bg-center{
    height: 130px;
  }
  .mobl span{
    font-size: 14px;
  }
  .mobl .text-black {
    color: black;
}
  .mobl .absolute{
position: static;
padding:0px;
  }
  .mobl img{
    height:105px;
    border-radius: 10px;
  }
.expBox{
  margin:5px 0;
}
.servicards .TyPeList{
  display: block;
 
}
.figure{
   margin:5px;
}
.servicards .TyPeList figure{
  max-width: 46%;
}
.ExploreAreaInner{
  padding: 50px 0;
}
.ExploreAreaInnerLeft{
  padding: 0 30px 20px 30px;
}

.ExploreAreaInnerRight .owl-item{
  margin: 0;
  padding: 0;
}
.add-box img{
  height: 150px;
}
.activity-bx{
  margin:10px 0;
}
.box-inner{
  margin:10px 0;
}
.repairMainarea h4{
  font-size: 18px;
  margin:10px 0;
}
.breadcrumb{
  display: none;
}
.d-flex div:last-child{
  display: none;
}
.filtercontainer{
  overflow-y: scroll;
  gap: 5px;
}
.commonflbtn{
  font-size: 15px;
}
.filtercontainer{
  margin-bottom: 7px;
}
.mobile_hide{
  display: none;
}
.our_servces h6{
  font-size: 24px;
}
.main-nav{
  display: block !important;
}
/* .navbar-area.inner_nav .header-top{
  display: block;
} */







/* Styling for mobile navigation */
.mobile-nav {
  background-color: #fff; /* Background color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for a subtle lift */
  position: relative;
}

/* Logo styling */
.mobile-nav .logo {
  display: block;
  height: auto;
}

/* Common button (Profile/Login button) */
.mobile-nav .common-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Example button background color */
  color: #fff; /* Button text color */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Button font size */
  text-decoration: none;
}

/* Main navigation styles */
.main-nav {
  background-color: #f8f9fa; /* Background color for main navigation */
}

/* Container within the main navigation */
.main-nav .container {
  padding: 0; /* Remove default padding */
}

/* Top header section */
.header-top {
  background-color: #f8f9fa; /* Background color */
  padding: 10px 0;
}

/* Top header section text alignment */
.header-top.text-end {
  text-align: end; /* Align text to the right */
}

/* Top header section image and text links */
.header-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-top ul li {
  display: inline-block;
  margin-right: 15px; /* Spacing between list items */
}

.header-top ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333; /* Link text color */
  font-size: 14px; /* Font size */
}

.header-top ul li a img {
  margin-right: 5px; /* Spacing between icon and text */
  height: 20px; /* Example height for icons */
}

/* Bottom header navigation */
.header_bottom {
  background-color: #fff; /* Background color */
  padding: 10px 0;
}

/* Navbar brand/logo */
.navbar-brand {
  margin-right: 15px; /* Spacing between brand/logo and menu items */
}

/* Navbar toggle button (hamburger icon) */
.navbar-toggler {
  border: none; /* Remove default border */
}

/* Mean menu container (collapsed navbar content) */
.mean-menu {
  margin-top: 10px; /* Optional margin */
}

/* Navbar links */
.navbar-nav .nav-item {
  margin-left: 10px; /* Spacing between navbar items */
}

.navbar-nav .nav-link {
  color: #333; /* Link text color */
  font-size: 14px; /* Font size */
  text-decoration: none;
  padding: 10px 15px;
}

.navbar-nav .nav-link img {
  height: 20px; /* Example height for icons */
  margin-right: 5px; /* Spacing between icon and text */
}
.header_bottom{
  height: 100vh;
  background: #000;
  width: 72%;
  position: absolute;
  z-index: 99;
}
.collapse:not(.show) {
  display: block;
}
.header_bottom{
  display: none;
}
.header_bottom .img-fluid:first-child{
  margin-left: 28%;
}

.header_bottom .nav-item{
  margin:5px 0;
  padding: 6px 0 !important;
}
.navbar-area.inner_nav .header-top{
  display: block;
}
.body_gray{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 9;
  opacity: 0.6;
  display: none;
}
.n_mobile_show{
  display: block !important;
}
.navbar-area .White_Nav .main-nav .navbar .navbar-nav .nav-item .nav-link{
  font-family: Judson;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.booking_area .nav-tabs .nav-link{
  background: transparent;
}
.booking_area .nav-tabs{
  margin-top:53%;
}
.booking_area .nav-tabs .nav-link{
  color: #000 !important;
  border: 1px solid lightgray;
  width: 50%;

}
.booking_area .nav-tabs .nav-link.active{
border-bottom: 1px solid lightgray;
}
.serviceInfoBx{
  display: block;
}
.mdl{
  --bs-modal-width: 92%;
  padding: 32px 0;
}

#navbarSupportedContent .navbar-nav .nav-item .nav-link{
  color:#fff !important;
}


}


